import PropTypes from 'prop-types';
import {
  Fade, ListItem, useMediaQuery, useTheme,
} from '@mui/material';
import Contact from '@/components/molecules/contact';
import { useDisplayEventTrackingOnMount } from '@/hooks/useDisplayEventTracking';
import { useClickEventTracking } from '@/hooks/useClickEventTracking';
import { formatPhoneNumberWithParentheses } from '@/myphoenix/utils/phone-functions';
import {
  PrimaryButton,
  SecondaryButton,
  StyledLink,
  ButtonText,
  Card,
  CardContent,
  CardActions,
  Label,
  HeaderWrapper,
  StyledIcon,
  AssignedContactsHeading,
  Heading,
  ContentWrapper,
  SpinnerWrapper,
  SubLabel,
  Loading,
  DateRange,
  MainContent,
  ContentSpacing,
  ListContent,
  AdditionalContentSpacing,
  Phone,
  FootNotes,
  SecondarySmallButton,
  CardActionsSmall,
} from './SingleTile.styles';

export default function SingleTile({
  icon,
  iconColor,
  darkMode,
  title,
  loading,
  mainContent,
  additionalContent,
  instructor,
  subLabel,
  footNotes,
  label,
  phone,
  primaryButtonText,
  primaryButtonCallback,
  primaryButtonAriaLabel,
  secondaryButtonCallback,
  secondaryButtonText,
  secondaryButtonAriaLabel,
  small,
  variant,
  list,
  dateRange,
  tileType,
  parentComponentName,
}) {
  const theme = useTheme();
  const trackClick = useClickEventTracking();
  const componentName = `${parentComponentName}_SingleTile`;
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  useDisplayEventTrackingOnMount(
    componentName,
    [{ title }, { label }, { loading }],
  );

  const primaryButton = primaryButtonText && (
    <PrimaryButton
      variant="contained"
      onClick={() => {
        trackClick(
          `${componentName}_PrimaryCta`,
          [{ text: primaryButtonText }],
        );
        primaryButtonCallback();
      }}
      aria-label={primaryButtonAriaLabel}
    >
      <ButtonText variant="body4" noWrap>
        {primaryButtonText}
      </ButtonText>
    </PrimaryButton>
  );

  let secondaryButton = secondaryButtonText && (
    small || mobile ? (
      <StyledLink
        role="button"
        $darkMode={darkMode}
        tabIndex={0}
        onClick={() => {
          trackClick(
            `${componentName} _SecondaryCta`,
            [{ text: secondaryButtonText }],
          );
          secondaryButtonCallback();
        }}
        onKeyPress={secondaryButtonCallback}
        aria-label={secondaryButtonAriaLabel}
      >
        {secondaryButtonText}
      </StyledLink>
    )
      : (
        <SecondaryButton
          variant="contained"
          onClick={secondaryButtonCallback}
          aria-label={secondaryButtonAriaLabel}
        >
          <ButtonText variant="body4" noWrap>
            {secondaryButtonText}
          </ButtonText>
        </SecondaryButton>
      )

  );
  if ((small || mobile) && !primaryButton && secondaryButtonText) {
    secondaryButton = (
      <SecondarySmallButton
        variant="contained"
        onClick={() => {
          trackClick(
            `${componentName}__SecondaryCta`,
            [{ text: secondaryButtonText }],
          );
          secondaryButtonCallback();
        }}
        aria-label={secondaryButtonAriaLabel}
      >
        <ButtonText variant="body4" noWrap>
          {secondaryButtonText}
        </ButtonText>
      </SecondarySmallButton>
    );
  }
  list.map((listItem) => (
    <ListItem key={listItem.item}>{listItem}</ListItem>
  ));
  return (
    <Card data-component={`myphx-single-tile-${tileType}`} $darkMode={darkMode} data-testid={componentName}>
      <CardContent>
        {label && <Label variant="body5" component="h2" $darkMode={darkMode}>{label}</Label>}
        <HeaderWrapper>
          {icon ? <StyledIcon icon={icon} fill={iconColor || theme.palette.primary.main} /> : null}
          {variant === 'assignedContacts'
            ? <AssignedContactsHeading variant="h2" component="h2">{title}</AssignedContactsHeading>
            : title && <Heading variant="h5" component={label ? 'h3' : 'h2'}>{title}</Heading>}
        </HeaderWrapper>
        {loading
          && (
            <SpinnerWrapper>
              <Fade in timeout={500} style={{ transitionDelay: '500ms' }}>
                <Loading role="progressbar" aria-label="Loading" />
              </Fade>
            </SpinnerWrapper>
          )}
        <ContentWrapper>
          {subLabel && <SubLabel>{subLabel}</SubLabel>}
          {dateRange && (
            <DateRange variant="body4" component="p">
              {dateRange}
            </DateRange>
          )}
          {mainContent && !additionalContent
            ? <ContentSpacing variant="body4" component="p">{mainContent}</ContentSpacing>
            : <MainContent variant="body4" component="p">{mainContent}</MainContent>}
          {!!list.length && (
            <ListContent>{list}</ListContent>
          )}
          {additionalContent && (
            <AdditionalContentSpacing>
              {additionalContent}
            </AdditionalContentSpacing>
          )}
          {instructor && (
            <Contact variant="namePlate" contactName={instructor} smallIcon darkMode={darkMode} />
          )}
          {instructor && phone && (
            <Contact variant="phone" phone={formatPhoneNumberWithParentheses(phone)} smallIcon darkMode={darkMode} />
          )}
          {phone && !instructor && (
            <div>
              <Phone
                $darkMode={darkMode}
                href={`tel:${phone}`}
              >
                {formatPhoneNumberWithParentheses(phone)}
              </Phone>
            </div>
          )}
        </ContentWrapper>
      </CardContent>
      {
        (footNotes || primaryButton || secondaryButton) && (
          small || mobile ? (
            <CardActionsSmall disableSpacing>
              {footNotes && <FootNotes>{footNotes}</FootNotes>}
              {primaryButton}
              {secondaryButton}
            </CardActionsSmall>
          ) : (
            <CardActions disableSpacing>
              {footNotes && <FootNotes>{footNotes}</FootNotes>}
              {secondaryButton}
              {primaryButton}
            </CardActions>
          )
        )
      }
    </Card>
  );
}

SingleTile.defaultProps = {
  iconColor: '',
  loading: false,
  phone: '',
  label: '',
  subLabel: '',
  mainContent: '',
  additionalContent: null,
  instructor: '',
  primaryButtonText: '',
  primaryButtonCallback: null,
  primaryButtonAriaLabel: '',
  secondaryButtonText: '',
  secondaryButtonCallback: null,
  secondaryButtonAriaLabel: '',
  small: false,
  footNotes: null,
  variant: null,
  list: [],
  dateRange: '',
  tileType: '',
  darkMode: false,
};

SingleTile.propTypes = {
  // eslint-disable-next-line react/require-default-props
  title: (props, propName) => {
    if ((!props.loading && (props[propName] === undefined || typeof (props[propName]) !== 'string'))) {
      return new Error('Please provide a title!');
    }
    return null;
  },
  // eslint-disable-next-line react/require-default-props
  icon: (props, propName) => {
    if ((!props.loading && (props[propName] === undefined || typeof (props[propName]) !== 'string'))) {
      return new Error('Please provide an icon value!');
    }
    return null;
  },
  iconColor: PropTypes.string,
  phone: PropTypes.string,
  subLabel: PropTypes.string,
  footNotes: PropTypes.element,
  label: PropTypes.string,
  instructor: PropTypes.string,
  loading: PropTypes.bool,
  mainContent: PropTypes.string,
  additionalContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  primaryButtonText: PropTypes.string,
  primaryButtonCallback: PropTypes.func,
  primaryButtonAriaLabel: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  secondaryButtonCallback: PropTypes.func,
  secondaryButtonAriaLabel: PropTypes.string,
  small: PropTypes.bool,
  variant: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.element),
  dateRange: PropTypes.string,
  tileType: PropTypes.string,
  darkMode: PropTypes.bool,
};
