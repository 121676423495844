import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Heading,
  CardContent,
} from '@/components/molecules/info-card/InfoCard.styles';
import { useDisplayEventTrackingOnMount } from '@/hooks/useDisplayEventTracking';

export default function InfoCard({
  heading, content, fullWidth, applyMarginBottom, applyMarginLeftToCardContent, assignedContacts,
  alumniTile, parentComponentName, tileType,
}) {
  const componentName = `${parentComponentName}_InfoCard`;

  useDisplayEventTrackingOnMount(
    componentName,
    [{ tileType }, { heading }],
  );

  return (
    <Card
      id={componentName}
      data-component={`myphx-info-card-${tileType}`}
      $alumniTile={alumniTile}
      $assignedContacts={assignedContacts}
      $applyMarginBottom={applyMarginBottom}
      $fullWidth={fullWidth}
    >
      { heading && (
        <Heading
          variant="body5"
          component="h2"
          $alumniTile={alumniTile}
        >
          { heading }
        </Heading>
      )}
      <CardContent
        $applyMarginLeftToCardContent={applyMarginLeftToCardContent}
      >
        { content }
      </CardContent>
    </Card>
  );
}

InfoCard.propTypes = {
  heading: PropTypes.string,
  tileType: PropTypes.string,
  content: PropTypes.element.isRequired,
  fullWidth: PropTypes.bool,
  applyMarginBottom: PropTypes.bool,
  applyMarginLeftToCardContent: PropTypes.bool,
  assignedContacts: PropTypes.bool,
  alumniTile: PropTypes.bool,
  parentComponentName: PropTypes.string,
};

InfoCard.defaultProps = {
  heading: '',
  tileType: '',
  fullWidth: false,
  applyMarginBottom: false,
  applyMarginLeftToCardContent: false,
  assignedContacts: false,
  alumniTile: false,
  parentComponentName: '',
};
