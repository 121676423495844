/* eslint-disable react/require-default-props */
import React from 'react';
import { Grid, Box } from '@mui/material';
import {
  StyledTypography,
  StyledButton,
  SecondaryTypography,
  StyledIcon,
  HoverLink,
} from '@/components/molecules/icon-item/IconItem.styles';
import { AppIcon } from '@/constants/icons';

type IconItemProps = {
  text: string,
  id: string,
  icon: AppIcon,
  type: string,
  callback?: Function,
  url?: string,
  secondaryText?: string,
  secondaryUrl?: string,
  darkMode?: boolean,
  parentId?: string,
  srText?: string
};

type IconContentProps = {
  type: string,
  text: string,
  url: string,
  callback: Function,
  darkMode?: boolean,
  srText?: string
};

type SecondaryContentProps = {
  secondaryText: string,
  secondaryUrl: string,
  parentId: string,
  darkMode?: boolean,
  srText?: string
};

function IconContent({
  callback, type, url, text, darkMode, srText,
}: IconContentProps) {
  const typography = (
    <StyledTypography
      type={type}
      variant="body4"
      $darkMode={darkMode}
      aria-hidden={srText === '' ? 'false' : 'true'}
    >
      {text}
    </StyledTypography>
  );

  if (type === 'link') {
    return (
      <HoverLink
        href={url}
        underline="none"
        $darkMode={darkMode}
      >
        {typography}
      </HoverLink>
    );
  }
  if (type === 'button') {
    return (
      <StyledButton
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => callback(event)}
      >
        {typography}
      </StyledButton>
    );
  }
  return typography;
}

function SecondaryContent({
  secondaryText, secondaryUrl, parentId, darkMode, srText,
}: SecondaryContentProps) {
  return (
    <HoverLink
      href={secondaryUrl}
      ml={4}
      underline="none"
      $darkMode={darkMode}
    >
      <SecondaryTypography
        variant="body4"
        $darkMode={darkMode}
        aria-hidden={srText === '' ? 'false' : 'true'}
      >
        {' '}
        {secondaryText}
        {' '}
      </SecondaryTypography>
      <Box pl={1} component="span">
        <StyledIcon id={`${parentId}-icon-item-long-arrow`} icon="icon-long-arrow" className="svg" aria-hidden="true" />
      </Box>
    </HoverLink>
  );
}

function IconItem({
  id,
  icon,
  text,
  type,
  callback,
  url,
  secondaryText,
  secondaryUrl,
  darkMode,
  parentId,
  srText = '',
}: IconItemProps) {
  return (
    <Grid
      container
      direction="column"
    >
      <Grid
        item
        container
        xs={12}
        wrap="nowrap"
        alignItems="center"
        direction="row"
      >
        <Grid item>
          {icon && (
            <StyledIcon id={id} icon={icon} className="icon" aria-hidden="true" />
          )}
        </Grid>
        <Grid item>
          {srText !== '' && (
            <span className="screenReaderText">{srText}</span>
          )}
          <IconContent
            type={type}
            text={text}
            url={url}
            callback={callback}
            darkMode={darkMode}
            srText={srText}
          />
        </Grid>
      </Grid>
      {secondaryText && (
        <Grid item xs={12}>
          <SecondaryContent
            secondaryText={secondaryText}
            secondaryUrl={secondaryUrl}
            parentId={parentId}
            darkMode={darkMode}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default IconItem;
